html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #557A95;
  color: white;
  margin: 0;
}

.subheader {
  font-family: 'Inter', sans-serif;
  font-size: 1.5em;
  font-weight: 600;
  text-transform: uppercase;
}

h1 {
  font-family: 'Inter', sans-serif;
  font-size: 4.375em;
  font-weight: 700;
  margin-bottom: 0;
}
h3 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 1.375em;
  margin-top: 0px;
  margin-bottom: 0px;
}

a {
  color: inherit;
  text-decoration: none;
}

.grid-container {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
  width: 95vw;
  max-width: 1065px;
  margin-left: auto;
  margin-right: auto;
  grid-template-areas:
    "navbar navbar"
    "hero-text hero-image"
    "about-icons about-text"
    "projects projects"
    "resume resume "
    "contact contact"
    "footer footer";
}

.grid-item-navbar {
  grid-area: navbar;
}

.hero-text, .hero-image, .about-icons, .about-text, .projects, .resume, .contact {
  margin-top: 200px;
}

.hero-text {
  grid-area: hero-text;
}

.hero-image {
  grid-area: hero-image;
}

.about-icons {
  grid-area: about-icons;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 50px;
  align-items: center;
}

.skill-icon {
  width: 100px;
}

.about-text {
  grid-area: about-text;
}

.projects {
  grid-area: projects;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resume {
  grid-area: resume;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link {
  margin-top: 15px;
}

.contact {
  background: linear-gradient(81.94deg, #7395AE 0%, #97D3FF 100%);
  border-radius: 50px;
  grid-area: contact;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact h1 {
  margin-top: 10px;
}

.footer {
  grid-area: footer;
  font-family: 'Inter', sans-serif;
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.footer-text {
  margin-top: 0px;
  margin-bottom: 12px;
}

.icons {
  display: flex;
  width: 100px;
  justify-content: space-evenly;
}

.stripe {
  background: linear-gradient(81.94deg, #7395AE 0%, #97D3FF 100%);
  width: 100%;
  height: 15px;
}

@media screen and (max-width: 1024px) {
  .headshot {
    width: 375px;
  }

  h1 {
    font-size: 3.75em;
  }
}

@media screen and (max-width: 850px) {
  .headshot {
    width: 300px;
  }

  h1 {
    font-size: 3.5em;
  }
}

@media screen and (max-width: 750px) {
  .grid-container {
    grid-template-areas:
      "navbar navbar"
      "hero-image hero-image"
      "hero-text hero-text"
      "about-icons about-icons" 
      "about-text about-text"
      "projects projects"
      "resume resume"
      "contact contact"
      "footer footer";
    column-gap: 0px;
    justify-items: center;
  }

  .about-icons, .projects, .resume, .contact {
    margin-top: 100px;
  }

  .hero-text, .about-text {
    margin-top: 0px;
  }

  .hero-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .hero-image {
    margin-top: 50px;
  }

  h1 {
    font-size: 2em;
  }

  .skill-icon {
    width: 75px;
  }

  .about-icons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 25px;
    justify-items: center;
    align-items: center;
  }

  .subheader {
    text-align: center;
  }
  
  .contact {
    width: 100%;
  }
}