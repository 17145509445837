.contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

input, textarea {
  background: rgba(0, 0, 0, 0);
}

input::placeholder, textarea::placeholder {
  color: white;
  opacity: 0.6;
}

.input {
  border-top: none;
  border-left: none;
  border-right: none;
}

.text-input, .box-input {
  font-family: inherit;
  font-size: inherit;
  width: 100%;
  box-sizing: border-box;
  padding: .25em;
  margin-bottom: 1em;
  border-style: solid;
  border-width: medium;
  max-width: 500px;
}

.text-input {
  border-top: none;
  border-left: none;
  border-right: none;
}

.text-input:focus, .box-input:focus {
  outline: none;
  transform: scale(1.04);
}

textarea {
  resize: none;
  height: 120px;
  border-radius: 15px;
}

.submit {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.submit-button {
  font-family: inherit;
  color: inherit;
  background-color: #5D5C61;
  border-width: 0px;
  border-radius: 50px;
  width: 150px;
  margin-bottom: 20px;
  margin-top: auto;
}

.submit-button:hover {
  cursor: pointer;
}

.submit-button .subheader {
  margin-top: 5px;
  margin-bottom: 5px;
}

.error {
  color: red;
  margin-bottom: 0.5em;
}

@media screen and (max-width: 750px) {
  .contact-form {
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
  }
}