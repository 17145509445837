.highlights-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 50px;
  flex-wrap: wrap;
  gap: 15px;
  align-items: stretch;
}

.highlight-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 325px;
  max-width: 525px;
  width: 75vw;
  background-color: #7395AE;
  border-radius: 15px;
  justify-content: flex-start;
  padding-bottom: 20px;
}

.highlight-card .subheader {
  margin-bottom: 0px;
}