.project-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 50px;
  flex-wrap: wrap;
  gap: 15px;
  align-items: stretch;
}

.project-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75vw;
  min-width: 250px;
  max-width: 345px;
  background-color: #7395AE;
  border-radius: 15px;
  justify-content: flex-start;
}

.icon {
  margin-top: 10px;
}

.card-p {
  margin-top: 0px;
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;
}

.card-button {
  color: inherit;
  background-color: #5D5C61;
  border-width: 0px;
  border-radius: 50px;
  width: 200px;
  margin-bottom: 20px;
  margin-top: auto;
}

.card-button:hover {
  cursor: pointer;
}

.card-button .subheader {
  margin-top: 10px;
  margin-bottom: 10px;
}